import { CountUpAnimation } from "../count-up-animation";

import { FactsProps } from "../../constants/types";

export const Facts = ({ facts }: FactsProps) => {
  const factsContainer = facts.map((fact, index) => (
    <div className="flex-fill" key={index}>
      <p className="flex-center h3 text-primary gap-x-1">
        <CountUpAnimation>{fact.number}</CountUpAnimation>
        <span className="whitespace-nowrap">{fact.unit}</span>
      </p>
      <p>{fact.subline}</p>
    </div>
  ));
  return (
    <div className="facts d-flex flex-wrap text-center">{factsContainer}</div>
  );
};
