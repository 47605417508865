import { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import { CountUpProps } from "../../constants/types";

const easeOutQuad = (t) => t * (2 - t);
const frameDuration = 1000 / 60;

export const CountUpAnimation = ({
  children,
  duration = 2000,
}: CountUpProps) => {
  const countTo = children;
  const [count, setCount] = useState(0);
  const [hasAnimated, setHasAnimated] = useState(false);

  return (
    <VisibilitySensor
      partialVisibility
      onChange={(isVisible) => {
        if (isVisible && !hasAnimated) {
          let frame = 0;
          const totalFrames = Math.round(duration / frameDuration);
          const counter = setInterval(() => {
            frame++;
            const progress = easeOutQuad(frame / totalFrames);
            setCount(countTo * progress);

            if (frame === totalFrames) {
              clearInterval(counter);
            }
          }, frameDuration);
          setHasAnimated(true);
        }
      }}
    >
      <span className="count-up">{Math.floor(count)}</span>
    </VisibilitySensor>
  );
};
